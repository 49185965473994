<template>
    <div>
        <van-popup v-model="show" position="bottom" :style="{ height: '60%' }" @click-overlay="close()">
            <div class="rap">
                <div class="title">
                    可用优惠券 <span>{{list.length}}张</span>
                </div>
                <div class="box">

                    <div class="item" v-for="(item,index) in list" :key="index" @click="selectItem(item)">
                        <div class="top">
                            <div class="left">
                                <div class="_up">{{item.coupon_name}}</div>
                                <div class="_down">{{item.coupon_desc}}</div>
                            </div>
                            <div class="right">
                                <div class="_left">
                                    <div class="__top"><span
                                            style="font-size:12px;">￥</span><span>{{Math.trunc(item.value)}}</span>
                                    </div>
                                    <div class="__bottom">资费抵扣可用</div>
                                </div>
                                <div class="_right">
                                    <img src="~@/assets/images/bigFlow/blueActive.png" v-if="selist[0]?selist[0].id == item.id:false" alt="">
                                    <img src="~@/assets/images/bigFlow/noActive.png" v-if="selist[0]?selist[0].id != item.id:true" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="down">
                           {{item.u_nickname?'该券来源于-----':'' }}{{item.u_nickname}}
                        </div>
                    </div>

                </div>

                <div class="bottom-btn">
                    <div class="left">优惠金额￥ <span>{{selist[0]? Math.trunc(selist[0].value):0}}</span></div>
                    <div class="right" @click="close()">确定</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>

// import coupons from "@/mixin/coupons.js";
export default {
    props: {
        showf: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        selist: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            show: false,
            // list:[],
        }
    },
    // mixins: [coupons],
    mounted() {
        // this.getList()
    },
    methods: {
        selectItem(item) {
            this.$emit('selectCoupsiItem',item)
        },
        close() {
            this.show = false
            this.$emit('closepop')
        }

    },
    watch: {
        showf: {
            handler() {
                this.show = this.showf
            },
            immediate: true
        }
    }

}
</script>
<style lang="less" scoped>
.rap {
    width: 100%;
    height: 100%;
    background-color: #f2f3f5;
    // padding: 20px 15px;
    box-sizing: border-box;
    position: relative;

    .title {
        font-size: 18px;
        padding: 20px 15px;
        box-sizing: border-box;
        font-weight: 600;

        span {
            font-size: 12px;
            color: #999999;
        }

        // margin-bottom: 20px;
    }

    .box {
        width: 100%;
        height: 320px;
        padding: 10px 15px;
        padding-bottom: 100px;
        background-color: #f2f3f5;
        box-sizing: border-box;
        overflow-y: auto;
        overflow: scroll;

        .item {
            height: 104px;
            width: 100%;
            background-color: #2d78f6;
            background: url('~@/assets/images/bigFlow/cardBg.png') no-repeat;
            background-size: 100%;
            margin-bottom: 10px;
            padding: 12px 14px;
            box-sizing: border-box;


            .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;

                .left {
                    ._up {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }

                    ._down {
                        padding: 4px 8px;
                        background-color: #e7f0ff;
                        color: #2d78f6;
                        border-radius: 6px;
                        display: inline-block;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    ._left {

                        .__top {
                            font-size: 36px;
                            color: #f67a33;
                        }

                        .__bottom {
                            font-size: 12px;
                            color: #f67a33;
                        }
                    }

                    ._right {
                        margin-left: 10px;

                        img {
                            width: 18px;
                            height: 18px;

                        }
                    }
                }
            }

            .down {
                color: #b6bcc7;
                height: 30px;
                line-height: 38px;
            }
        }
        // .item:last-child{
        //     // margin-bottom: 104px;
        // }

    }

    .bottom-btn {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        // padding-top: 10px;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box;
        height: 70px;
        width: 100%;
        background-color: #f9fafc;
        z-index: 99;

        .left {
            font-size: 14px;
            font-weight: 600;

            // color: aqua;
            span {
                font-size: 18px;
            }
        }

        .right {
            width: 100px;
            height: 36px;
            background-color: #2d78f6;
            color: #fff;
            text-align: center;
            line-height: 36px;
            border-radius: 8px;
        }
    }
}
</style>