<template lang="">
    <div class="box">
        <div class="card">
            <div class="title">购买超值优惠，享每月减免 <span style="color:#ea1111">{{Math.trunc(item.coupon_amount)}}元</span> 优惠</div>
            <div class="center">
                <div class="counpbox">
                    <div class="left">
                        <span class="big" >{{Math.trunc(item.coupon_amount)}}</span>    
                        <span class="small">元</span>    
                        <span class="small" style="margin:0 4px 0 6px">X</span>    
                        <span class="big">{{item.coupon_num}}</span>    
                        <span class="small">张</span>    
                    </div>
                    <div class="right">
                        无门槛    
                    </div>
                </div>
                <div class="price">
                    <span style="font-family:SourceHanSansCN;font-weight:600;font-size:14px;">￥100</span>
                    <img src="@/assets/images/selectpack/duigou.png" alt="">
                </div>
            </div>
            <div class="lasttext">本次可用 丨 {{item.coupon_num}}个月内有效</div>
        </div>



        <div class="buy">

            <div class="title">
                <span>充值金额</span>
                <span>月包105G通用流量</span>
            </div>

            <div class="smallCard">
                    <div>
                        <span style="color:#666666;font-size:16px;font-weight:600;">月费<span style="color:#f95826;font-size:20px;font-weight:600;">{{Math.trunc(item.first_package_price)}}</span>元</span>
                    </div>
                    <div class="oldPriceText">
                        <span style="text-align:center;">原价{{Math.trunc(Number(item.coupon_amount)+Number(item.first_package_price))}}元/月</span>
                        <span>已优惠{{Math.trunc(item.coupon_amount)}}元</span>     
                    </div>

            </div>

            <div class="btnOk" @click="submmit()">
                立即充值（￥{{Math.trunc(Number(item.money)+Number(item.first_package_price))}}）
            </div>

        </div>
    </div>
</template>
<script>


export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            timer: null

        }
   
    },
    methods: {
        submmit() {
            let num = Math.trunc(Number(this.item.money) + Number(this.item.first_package_price))
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.$emit('buyNewCounp', num,this.item.first_package_price)
            }, 1000)
        }
    },
}
</script>

<style lang="less" scoped>
.box {

    .buy {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        height: 207px;
        margin-top: 15px;
        padding-bottom: 40px;
        background-color: #fff;

        .title {
            span:first-child {
                font-size: 16px;
                color: #242424;
                font-weight: 700;
                margin-right: 10px;
            }

            span:last-child {
                font-size: 10px;
                color: #abb4c4;
                margin-right: 10px;
            }


        }

        .smallCard {
            margin-top: 15px;
            width: 160px;
            height: 80px;
            background: url('~@/assets/images/selectpack/counpcard.png') no-repeat;
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .oldPriceText {
                margin-top: 4px;
                font-size: 10px;
                color: #abb4c4;

                span:first-child {
                    margin: 0 2px;
                }
            }
        }


        .btnOk {
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto;
            width: 220px;
            height: 45px;
            background-color: #ea1111;
            margin-top: 10px;
            border-radius: 30px;
            text-align: center;
            line-height: 45px;
            font-size: 17px;
            color: #fff;
            background-image: linear-gradient(90deg, #ff9b5b, #fe5d36);
            box-shadow: 0 1px 4px 2px gainsboro;
        }

        .btnOk:active {
            background-image: linear-gradient(90deg, #ce7b48, #d85635);
        }
    }


    .card {
        width: 345px;
        // height: 150px;
        background-color: #fff;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 15px 10px;
        margin: 0 auto;

        .title {
            font-size: 16px;
            color: #242424;
            font-weight: 700;

        }

        .center {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 17px;

            .counpbox {

                width: 240px;
                height: 64px;
                background: url('~@/assets/images/selectpack/counp.png') no-repeat;
                background-size: 100%;

                display: flex;
                justify-content: space-between;
                padding: 20px 22px;
                box-sizing: border-box;
                align-items: center;

                .big {
                    font-weight: 600;
                    font-size: 24px;
                    color: #ea1111;
                    margin-right: 2px;
                }

                .small {
                    color: #ea1111;
                    font-size: 12px;
                }

                .right {
                    color: #fff;
                    font-size: 14px;
                }

            }

            .price {
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                }
            }
        }


        .lasttext {
            margin-top: 15px;
            color: #abb4c4;
        }
    }
}
</style>