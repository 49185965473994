<!--
 * @Author: your name
 * @Date: 2022-04-06 11:26:47
 * @LastEditTime: 2022-06-22 17:05:09
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D

%E7%BD%AE
 * @FilePath: \newwifi\src\views\selectpack\index.vue
-->
<template>
  <div class="selectpack">
    <div class="top">
      <img style="width: 100%; height: 100%" src="@/assets/images/selectpack/banner4.png" alt="" />
      <!-- <img v-if="activeInfo.real_type == 3" style="width:100%;height:100%;" src="@/assets/images/selectpack/movebg.jpg" alt=""> -->
    </div>
    <div class="center">
      <div class="titlebox">
        <div :class="tabActive == 0 ? 'data100 tabson' : 'tabson tabson1'" @click="tabActiveFn(0)"
          v-if="newList[0] ? true : false">
          <img class="recomendIcon" src="@/assets/images/selectpack/recomend.png" alt=""
            v-if="newList[0] ? newList[0].is_recommend : false" />
          <img class="jiao" src="@/assets/images/selectpack/jiao.png" alt="" v-if="tabActive == 0" />
          <span>月享</span>
          <span class="centerNumber">{{
          newList[0] ? newList[0].name : ""
          }}</span>
          <span>套餐</span>
          <div class="line" v-if="tabActive == 2"></div>
        </div>
        <div :class="tabActive == 1 ? 'data300 tabson' : 'tabson'" @click="tabActiveFn(1)"
          :style="{ borderTopRightRadius: newList[2] ? 'none' : '10px' }" v-if="newList[1] ? true : false">
          <img class="recomendIcon" src="@/assets/images/selectpack/recomend.png" alt=""
            v-if="newList[1] ? newList[1].is_recommend : false" />
          <img class="jiao" src="@/assets/images/selectpack/jiao.png" alt="" v-if="tabActive == 1" />
          <span>月享</span>
          <span class="centerNumber">{{
          newList[1] ? newList[1].name : ""
          }}</span>
          <span>套餐</span>
          <div class="line" v-if="tabActive == 0"></div>
        </div>
        <div :class="tabActive == 2 ? 'data10 tabson' : 'tabson tabson3'" @click="tabActiveFn(2)"
          v-if="newList[2] ? true : false">
          <img class="recomendIcon" src="@/assets/images/selectpack/recomend.png" alt=""
            v-if="newList[2] ? newList[2].is_recommend : false" />
          <img class="jiao" src="@/assets/images/selectpack/jiao.png" alt="" v-if="tabActive == 2" />
          <span>月享</span>
          <span class="centerNumber">{{
          newList[2] ? newList[2].name : ""
          }}</span>
          <span>套餐</span>
        </div>

        <!-- :class="{ change: !ischange }" -->
        <!-- <smalltitle
          :info="info"
          :iccid="iccid"
          @setWifi="newswifiShow = true"
        ></smalltitle> -->
        <!-- <div
          class="infobox"
          v-if="ischange"
        >
          <funboxitem
            v-for="(item, index) in infolist"
            :key="index"
            :hownum="infolist.length"
            :item="item"
            :textcolor="'#47525d'"
            :bottomtext="'#8a9ab5'"
          >
            <div class="text-top"><span
                class="sapn"
                :class="{ isstatus: index == 3 ? true : false }"
                :style="{ color: item.texttop }"
              >{{ item.money }}</span>{{ item.company }}</div>
          </funboxitem>
        </div> -->
      </div>
      <div class="mealbox">
        <div class="ispurchange">
          <!-- <div class="left">套餐类型</div> -->
        </div>
        <div class="box">
          <van-radio-group v-model="vantradio" @change="vantChange">
            <van-radio :name="item.id" v-for="item in list" :key="item.id" @click="radioclick(item)">
              <img src="@/assets/images/selectpack/hot.png" alt="" class="hot" v-if="item.is_hot == 1" />
              <div class="vanttitlebox" :class="{
                selectradio:
                  item.id == vantradio && info.has_free_package == 1,
                nohaspackage:
                  item.id == vantradio && info.has_free_package == 0,
                haspackage:
                  item.id != vantradio && info.has_free_package == 0,
              }">
                <div class="vanttitle">
                  <div class="title-left">
                    <p class="pone" :class="{ selectpone: item.id == vantradio }">
                      {{ item.short_name }}
                    </p>
                    <p class="ptwo" :class="{ selectptwo: item.id == vantradio }">
                      {{ item.limit_desc ? item.limit_desc : "超值特惠" }}
                    </p>
                  </div>
                  <div class="title-right">
                    <p class="right-pone" :class="{ rightspone: item.id == vantradio }">
                      <span class="span" :class="{ rightspan: item.id == vantradio }">￥</span>{{ info.cur_buy_id == 0 && couponslist.length == 0?item.discount_price:item.price }}
                    </p>
                    <p v-if="item.month_desc == ''" class="right-ptwo" :class="{ srightptwo: item.id == vantradio }">
                      约￥{{
                      info.cur_buy_id == 0 && couponslist.length == 0?Math.floor(item.discount_price):Math.floor(item.price / (item.validity_day / 30))
                      }}/月
                    </p>

                    <div v-if="item.month_desc != ''" class="firstMonth">
                      <img src="@/assets/images/selectpack/upicon.png" alt="">
                      <p>{{item.month_desc}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="mealinfo" :class="{ isweixininfo: weixin }">
        <div class="mealinfo-title">
          <div class="met-left">套餐简述</div>
          <div class="met-right">
            <div class="box-right" v-for="(item, index) in list" :key="index"
              :class="{ 'met-sright': item.id == vantradio }"></div>
          </div>
        </div>
        <div class="info" v-for="(item, index) in mealList" :key="index">
          <div class="info-left">
            <img :src="item.url" alt="" class="img" />
            <div class="text">{{ item.title }}</div>
          </div>
          <div class="info-right" :class="{ infoadj: item.id == 3 ? true : false }">
            {{ item.info }}
          </div>
        </div>
        <div class="showMealInfo">{{showMealInfo}}</div>
      </div>

      <paydetail v-if="couponslist.length != 0" @openPop="openPopFn()" :selist="selectList" :itemPrice="moneyitem.price"></paydetail>

      <paymode @change="paychange($event)" v-if="!weixin"></paymode>
    </div>
    <div class="bottom" :style="{bottom:isApp == 1?'0':'65px'}">
      <dir class="bottom-box">
        <div class="bottom-left">
          <div class="left" @click="showqrcode = !showqrcode">
            <img src="@/assets/images/selectpack/service.png" alt="" class="img" />
            <div class="blb-text">咨询</div>
          </div>
          <img src="@/assets/images/selectpack/line.png" alt="" class="img-top" />
          <div class="right">
            <div class="money">
              <!-- 如果有优惠券  再判断套餐的价格 减去优惠券的价格 是否 大于 0  如果是 显示 计算后的金额  如果不是显示0   如果没有优惠券，显示套餐金额-->
              <span class="span">￥</span>{{selectList[0]? moneyitem.price - selectList[0].value>0? moneyitem.price - selectList[0].value:0: info.cur_buy_id == 0 && couponslist.length == 0?moneyitem.discount_price:moneyitem.price }}
              <!-- 如果没用优惠券  判断是不是首月 和 第六月， 是的话 显示 计算价格 -->
            </div>
            <div class="text">金额</div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="submit" v-if="prohibit">
            <van-loading color="#fff" size="16px" />支付中...
          </div>
          <div class="submit" v-else @click="clkbuy">订购套餐</div>
        </div>
      </dir>
    </div>
    <van-popup v-model="reshow" position="bottom" :style="popupHeight" style="background-color: #f6f8fc !important;" round closeable>
      <div class="popup-top">
        <div class="pop-center">
          <div class="popup-title">
            ICCID:<span>{{ iccid }}</span>
          </div>
          <div class="popup-toast">
            <span class="money">{{ info.c_balance }}</span><span class="company">元</span>当前余额不足,请及时充值！
          </div>
        </div>
        <div class="buyNewCounp" v-if="newBuyCounp">
          <buyCounp :item="meallistbox[0]" @buyNewCounp="buyNewCounpFn" ></buyCounp>
        </div>
        <div v-else>
        <div class="center-box">
          
          <p class="center-text">充值金额</p>
          <div class="moneybox">
            <mealbox v-for="item in meallistbox" :key="item.id" :item="item" :give="!isOrder" :active="active"
              @selectitem="selectitem($event)" :flag="true"></mealbox>
          </div>

        
          <div class="inmeal">
            <!-- {{ info.pay_status == 0 ? "未订购套餐" : info.cur_buy_name }} -->
          </div>
        </div>
        <!-- 购买优惠券 -->

      
          
        </div>
   
      </div>
     
      <bluebutton v-if="!newBuyCounp" :title="titletext" @clkbutton="clkbutton" :isloading="isloading"></bluebutton>
    </van-popup>
    <qrcode :showqrcode="showqrcode" @clkqrcode="clkqrcode">
      <img :src="url" alt="" class="qrcode-img" />
    </qrcode>
    <div class="setvan">
      <van-overlay :show="show" @click.stop>
        <div class="re-box">
          <div class="text">
            <p class="h3">请您确认购买套餐</p>
            <!-- 本次购买套餐流量将进行叠加，优先使用之前套餐流量，之前套餐有效期不变，本次套餐有效期止 -->
            本次购买套餐流量将进行累加，优先使用之前套餐流量，之前套餐有效期不变，之前套餐到期后本期套餐生效
            <!-- {{ moneyitem.validity_day }}天后！ -->
          </div>
          <div class="button">
            <div class="button-right" @click="show = false">取消</div>
            <div class="button-left" @click="query">确定</div>
          </div>
        </div>
      </van-overlay>
    </div>
    <!-- <newswifi
      :show="newswifiShow"
      @clkOverlay="newswifiShow = !newswifiShow"
      @change="change"
      :cardinfo="info"
    >
    </newswifi> -->
    <querytoastbox :show="queryShow" @clkleft="clkleftFn()" @clkright="clkQuqeryRight">
      <template #center>
        <div class="querycenter">即将打开"通讯网服"小程序</div>
      </template>
    </querytoastbox>
    <querytoastbox :show="alipayshow" :bottomInfo="PaybottomInfo" @clkleft="clkAlipayLeft" @clkright="clkAlipayRight">
      <template #center>
        <div class="querycenter">是否支付成功</div>
      </template>
    </querytoastbox>
    <confirmbox :show="newcomshow" @closebtn="closebtn()" @determine="determine"></confirmbox>
    <querytoastbox :show="newbuttomshow" :isnewButtm="true" @clknew="clknew">
      <template #center>
        <div class="newquerycenter">
          多次打开微信支付可能会被限制，如果无法正常唤起请选择支付宝支付
        </div>
      </template>
    </querytoastbox>

    <transition name="van-fade">
      <div v-show="mask">

        <div class="mask" v-if="mask">
          <div class="box">
            <img src="@/assets/images/distributionIndex/tq.png" alt="" @click="mask =false">
            <span class="countTo">
              <countTo :startVal='startVal' :endVal='endVal' :duration='startTime' :autoplay="maskflage"
                :useEasing="false" @mountedCallback="countFn()"></countTo>
            </span>
            <span class="subtraction">-10</span>
            <!-- <div class="amount">
          <div style="display: flex;">
            <transition-group name="list" tag="p" style="position: relative;">
              <div style="display: inline-block;position: absolute;" v-for="(item , index) in todayAmountComputed"
                :style="{
                   left:  index * 30 + 'px',
                   'transition-delay': 0.1 * index + 's'
                }" :key="item + index">
                {{item}}</div>
            </transition-group>
          </div>
        </div> -->
          </div>
        </div>
      </div>
    </transition>
    <popup :showf="showpop" @closepop="closepopFn" :list="couponslist" :selist="selectList"
      @selectCoupsiItem="selectCoupsiItemFn"></popup>

  </div>
</template>
<script>

import {
  getCouponsList
} from "@/untils/distubritionIndex.js";
import countTo from 'vue-count-to';
import isAli from "@/untils/isAli";
import confirmbox from "@/components/confirmbox/index.vue";
import querytoastbox from "@/components/querytoastbox/index.vue";
import isweixin from "@/untils/isweixin.js";
import paymode from "@/components/paymode/index.vue";
import smalltitle from "@/components/smalltitle/index.vue";
import paydetail from "@/components/priceDetail/index.vue"; //价格明细

import funboxitem from "@/views/index/components/funboxitem.vue";
import paymixin from "@/mixin/paymixin.js";
import mixin from "@/mixin/mixin.js";
// import coupons from "@/mixin/coupons.js";
import mealbox from "@/components/mealbox/index.vue";
import bluebutton from "@/components/bluebutton/index.vue";
import qrcode from "@/components/qrcode/index.vue";
// import isPhone from "@/untils/isPhone.js";
import popup from './components/popup.vue'
import buyCounp from './components/buyCounp.vue'
// import newswifi from "@/components/newswifi/index.vue"
export default {
  components: {
    buyCounp,
    popup,
    smalltitle,
    funboxitem,
    mealbox,
    bluebutton,
    qrcode,
    // newswifi,
    paymode,
    querytoastbox,
    confirmbox,
    countTo,
    paydetail
  },
  data() {
    return {
      newBuyCounp:false, // 新的充值弹框
      showpop: false,
      startTime: 4000,
      todayAmount: '39',
      startVal: 39, //初始数值
      endVal: 29, //结束数值
      maskflage: false,
      mask: false, //特权券显示控制
      phoneNumber: "",
      isOrder: false, //是否订购了套餐
      phoneShow: false, //绑定手机号的弹框
      tabActive: 0, //tab栏的索引 选中值
      newbuttomshow: false, // 展示单个按钮的弹窗
      PaybottomInfo: {
        left: "取消支付",
        right: "支付成功",
      }, // 微信和支付宝支付跳转前的询问
      newcomshow: false, // 实名认证弹窗提示
      bottomInfo: {
        left: "取消",
        right: "确认",
      }, // 询问对话框的底部文字
      alipayshow: false, // 支付宝返回弹窗
      queryShow: false, // 是否显示确认前往微信小程序
      weixin: false, // 是否在微信环境下
      vantradio: 1, // vant组件库单选框默认选中的item
      infolist: [
        {
          text: "账户余额",
          money: "",
          company: "元",
          texttop: "#47525d",
        },
        {
          text: "已用",
          money: "",
          company: "",
          isloading: true,
          color: "#1a6af1",
          texttop: "#47525d",
        },
        {
          text: "剩余天数",
          money: "",
          company: "天",
          texttop: "#47525d",
        },
        {
          text: "设备状态",
          money: "",
          company: "",
          texttop: "#47525d",
        },
      ], // 详细信息区域列表
      radio: "1", // 默认选中的套餐
      info: {}, // 头部详细信息
      list: [], // 正常套餐列表
      iccid: "", // 套餐设备id
      moneyitem: "", // 选中的套餐详情
      mealList: [
        {
          url: require("@/assets/images/selectpack/balance.png"),
          title: "套餐资费",
          info: "",
          id: 1,
        },
        {
          url: require("@/assets/images/selectpack/flow.png"),
          title: "套内流量",
          info: "",
          id: 2,
        },
        {
          url: require("@/assets/images/selectpack/calendar.png"),
          title: "有效期",
          info: "",
          id: 3,
        },
      ], // 套餐详情列表

      showMealInfo: '', //套餐详情文字展示
      prohibit: false, // 支付按钮被点击
      reshow: false, // 余额不足的充值弹窗
      meallistbox: [
        // {
        //   money: 80,
        //   id: 1,
        // },
      ], // 充值金额数组
      titletext: "立即充值",
      active: 1, // 默认选中的值
      activeItme: {
        money: 80,
        id: 1,
        first_package:'0.00'
      }, // 默认选中的充值钱数
      isloading: true, // 是否显示加载Loading
      showqrcode: false, // 二维码弹出
      url: require("@/assets/images/guide/qrcode.jpg"),
      ischange: false, // 是否展示头部的设备详细信息
      popupHeight: {}, // 根据手机系统判断弹出高度
      is_first: 1, // 是不是第一次购买
      show: false, // 确定购买弹窗
      newswifiShow: false, // 新的wifi弹窗是否显示(用于切换wifi设备)
      payradio: "1", // 支付方式 1是微信2是支付宝
      newoffcardinfo: {}, //官方的详细信息
      rightNum: 0, // 点击允许跳转小程序按钮次数
      activeInfo: {}, //当前设备信息
      newList: [],
      isRecommend: {},
      timer: null,
      arr: [],
      couponslist: [],//优惠券列表
      selectList: [],// 选择的优惠券
      first_package:null,//第一个
      isApp:0,//判断浏览器携带的参数，如果是1表示在app和微信浏览器里打开，如果是0表示浏览器
    };
  },
  computed: {
    todayAmountComputed() {
      return this.todayAmount.split('');
    },
  },
  mixins: [paymixin, mixin],
  created() {
    //获取充值列表
    if (this.$route.query.dn) {
      window.sessionStorage.setItem('iccid', this.$route.query.dn)
    }
  },
  mounted() {
    var url = window.location.href;
        if (url.indexOf('?') > -1) {
            var cs = url.split('?')[1];
            var cs_arr = cs.split('&');
            var cs = {};
            for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
                cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
            }
            if(cs.app == undefined){
                this.isApp = 0
            }else{
                this.isApp = cs.app 
            }
        } else {
            this.isApp = 0
        }

    // console.log( this.$router.query.openid);
    // if(this.$route.query.openid ){ sessionStorage.setItem('openid', this.$route.query.openid) }
    // var openid = window.location.href.split("#")[1];
    // console.log(window.location.href.split("&"));
    this.setIccid();
    this.getcardstate();
    this.getInfo(); //获取手机号 ，
    this.weixin = isweixin();

    // 根据机型判断高度
    this.setPHeight();
    // JS原生设置当前背景颜色
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f3f5f7");
    this.getList();
    this.iccid = sessionStorage.getItem("iccid");
    // this.getoffinfo()
    //计算变量
    this.getUrlQcode();
    // 如果是支付宝环境phone
    if (isAli()) {
      this.payradio = "2";
    }
    this.getCoupList()

 
  },
  methods: {
    //购买优惠券的弹窗
    buyNewCounpFn(data,data2){
        console.log(data);
        console.log(data2);
        this.activeItme.money = data
        this.activeItme.first_package = data2
        this.clkbutton()
    },



    selectCoupsiItemFn(item) {
      // 有
      if (this.selectList.length != 0) {
        //是否是当前选择的 
        if (item.id == this.selectList[0].id) {
          this.selectList = []
        }else{
          this.selectList = []
          this.selectList.push(item)
        }


      } else {
        this.selectList.push(item)
        // this.price = item.value
      }

      console.log(this.selectList);
      //如果有优惠券  点击  =》 取消 
      //如果没有选择优惠券  点击   加入

    },


    getCoupList() {
      let data = {
        phone: sessionStorage.getItem("phone"),
        openid: sessionStorage.getItem("openid"),
        state: 1
      }
      getCouponsList({ data }).then(res => {
        // console.log(res);
        if(res.data.code != 0 ){
          this.couponslist = res.data.list
    
        if (this.couponslist.length != 0) {
          this.selectList.push(this.couponslist[0])
          // this.selectList.push(this.couponslist[0])
          // this.price = this.couponslist[0].value    
        }
        }else{
          this.couponslist = []
        }
    
      })
    },


    closepopFn() {
      this.showpop = false
    },
    // 打开优惠券列表


    openPopFn() {
      this.showpop = true
    },
    // 降价效果
    privilege() {
      if (this.list[0].old_price - this.list[0].price > 0) {
        this.startVal = Math.trunc(this.list[0].old_price)
        this.endVal = Math.trunc(this.list[0].price)
        this.mask = true
        this.maskflage = true
      }

    },

    async RechargeList() {
      let data = {
        iccid: sessionStorage.getItem("iccid"),
      };
      await this.getRechargeList({ data }).then((res) => {
        console.log(res,'充值列表');
        this.meallistbox = res.data.list;
        if(Number(this.meallistbox[0].first_package_price)>0)  this.newBuyCounp = true

        
        // this.activeItme.id =this.meallistbox[0].id
        // this.activeItme.money = this.meallistbox[0].money
      });
    },

    //弹框取消点击按钮
    clkleftFn() {
      this.queryShow = false;
      this.prohibit = false;
    },

    // 取到iccid并且存到本地缓存中
    setIccid() {
      if (
        sessionStorage.getItem("iccid") != "null" &&
        sessionStorage.getItem("iccid") != null &&
        sessionStorage.getItem("iccid") != undefined &&
        sessionStorage.getItem("iccid") != "undefined"
      ) {
        this.iccid = sessionStorage.getItem("iccid");
      } else {
        this.iccid = this.$route.query.dn;
        sessionStorage.setItem("iccid", this.iccid);
      }
    },

    tabActiveFn(num) {
      if (this.newList[num]) {
        this.tabActive = num;
        this.list = this.newList[num].list;
        // this.moneyitem = this.newList[num].list[0]
        this.radioclick(this.newList[num].list[0]);

        this.vantradio = this.newList[num].list[0].id;

      }


    },

    //计算已用的流量
    subMoney() {
      this.infolist[1].money = Math.round(
        Math.ceil(this.activeInfo.total_flow - this.offcardinfo.surplus_flow) /
        1024
      );
      this.infolist[1].company = "GB";
    },

    // 关闭允许次数过多，弹起来的微信支付被封提示
    clknew() {
      this.newbuttomshow = false;
      let iccid = window.sessionStorage.getItem("iccid");
      let data = {
        iccid: iccid,
        type: 1,
      };
      this.getUrl(data);
      setTimeout(() => {
        this.alipayshow = true;
      }, 1000);
    },
    closebtn() {
      this.newcomshow = false;
    },
    determine() {
      this.newcomshow = false;
      // window.location.href="http://iot.yunruiot.com/certif_entry.html?userCode=r/s9Tc00hjiKcR13MIjZHg==&passWord=9u8VuY1xbez6r6k/BBnLlw==&phone="+this.info.phone+"&accessNbr="+this.info.new_iccid
      window.location.href = this.info.real_url;
    }, // 是否点击了确定实名认证
    // 支付宝回来的弹窗点击了重新支付按钮
    clkAlipayLeft() {
      this.alipayshow = false;
    },
    // 支付宝回来后点击了确定按钮
    clkAlipayRight() {
      if (
        this.info.cur_buy_id > 0 &&
        JSON.stringify(this.newoffcardinfo) != "{}" &&
        this.newoffcardinfo.ispick != 2
      ) {
        this.alipayshow = false;
        this.newcomshow = true;
      } else {
        this.$router.push("/index");
      }
    },
    clkQuqeryRight() {
      // 点击了确定前往微信小程序
      this.rightNum++;
      if (this.rightNum < 2) {
        setTimeout(() => {
          this.alipayshow = true;
        }, 1000);
      }
      this.jumpApplet();
    },
    // 支付方式发生了改变
    paychange($event) {
      this.payradio = $event;
    },
    // vant单选框组件
    vantChange(name) {
      this.vantradio = name;
    },
    // vant的radio被点击了
    radioclick(item) {
      console.log('按钮',item)
      this.moneyitem = item;
      this.mealList[0].info = item.show_name;
      this.mealList[1].info = item.con_unit;
      this.mealList[2].info = item.validity_desc
      this.showMealInfo = item.pack_desc
    },
    // 监听切换wifi设备
    // change () {
    //   this.newswifiShow = false
    //   this.iccid = sessionStorage.getItem("iccid")
    //   this.infolist[1].money = ""
    //   this.infolist[1].company = ""
    //   this.infolist[1].isloading = true
    //   this.getList()
    //   this.getInfo()
    //   this.getoffinfo()
    // },
    // 点击购买套餐
    clkbuy() {
      if (this.phoneShow == true) {
        this.$router.push("/index");
        return false;
      }
      this.info.pay_status == 1 ? (this.show = true) : this.pay();
    },
    // 根据机型判断高度
    setPHeight() {
      if (this.isApp && document.documentElement.clientHeight <= 667) {
        this.popupHeight = {
          height: "70%",
        };
      } else {
        this.popupHeight = {
          height: "60%",
        };
      }
    },
    // 关闭弹出层
    clkqrcode() {
      this.showqrcode = false;
    },
    // 获取企业二维码
    getUrlQcode() {
      this.getWechatQRcode().then((res) => {
        this.url = res.data.info.wechat_img;
      });
    },
    getInfo() {
      let data = {
        iccid: sessionStorage.getItem("iccid")
          ? sessionStorage.getItem("iccid")
          : this.iccid,
      };
      this.getindexinfo({ data }).then((res) => {
        if (res.data.code == 1) {
          // console.log(res);
          this.newoffcardinfo = res.data.info;
          this.phoneNumber = res.data.info.phone;
          //sessionStorage.setItem('phone',res.data.info.phone)
          // this.isOrder = res.data.info.buy_id
          if (res.data.info.buy_id > 0) this.isOrder = true;
          this.activeInfo = res.data.info;
          if (this.activeInfo.sl_real_state == 0) {
            this.phoneShow = true;
          } else {
            this.phoneShow = false;
          }
        }
      });
    },

    // 获取官方信息
    getoffinfo() {
      let data = {
        iccid: sessionStorage.getItem("iccid")
          ? sessionStorage.getItem("iccid")
          : this.iccid,
      };
      this.officialState({ data }).then((res) => {
        console.log(res,'res');
        if (res.data.code == 1) {
          this.offcardinfo = res.data.info;
          this.newoffcardinfo = res.data.info;
          // console.log(  this.newoffcardinfo ,'dddd');
          this.infolist[1].money = res.data.info.used;
          this.infolist[1].company = res.data.info.used_unit;
          this.infolist[1].isloading = false;
          this.subMoney();
        } else {
          this.$toast(`${res.data.msg}`);
        }
      });
    },
    // 选中的actice
    selectitem($event) {
      console.log($event);
      this.active = $event.id;
      this.activeItme = $event;
    },
    // 跳往微信小程序
    jumpApplet() {
      if (!this.weixin && this.payradio == 1) {
        if (this.rightNum < 2) {
          let iccid = window.sessionStorage.getItem("iccid");
          let data = {
            iccid: iccid,
            type: 1,
          };
          this.getUrl(data);
        }
        if (this.rightNum >= 2) {
          this.alipayshow = false;
          this.queryShow = false;
          this.newbuttomshow = true;
        }
      }
      return false;
    },
    // 立即充值按钮
    clkbutton(pay) {
      //如果不是在微信里 跳转小程序
      if (!this.weixin && this.payradio == 1) {
        this.queryShow = true;
        return false;
      }
      if (this.timer) {
        return;
      }
      this.isloading = false;
      this.timer = setTimeout(() => {
        //
        let data = {
          price: this.activeItme.money, // 金额
          iccid: this.iccid, // 套餐设备id
          phone: sessionStorage.getItem("phone"), //手机号
          type: this.ischange ? 2 : 1, //购买类型
          buy_id: this.moneyitem.id, // 购买id
          platform: 1, // 1是h5
          openid: sessionStorage.getItem("openid"), // 微信openid
          pay_type: this.payradio == 1 ? "1" : "5", // 支付状态1是微信5是支付宝
          coupon_id:this.selectList[0]?this.selectList[0].id:'',
          first_package_price:this.activeItme.first_package
        };
        // console.log(this.activeItme);
        // console.log(data);
        this.setpayinfo(data);

        this.timer = null;
      }, 1000);
    },
    // 关闭弹出层
    close() {
      this.reshow = false;
    },
    query() {
      this.show = false;
      if (this.timer) {
        console.log(this.timer);
        return;
      }
      this.prohibit = true;
      this.timer = setTimeout(() => {
        this.timer = null;

        this.pay();
      }, 1000);
    },
    // 支付按钮
    async pay() {
      await this.RechargeList();
      if (!this.weixin && this.payradio == 1) {
        this.queryShow = true;
        return false;
      }
      this.prohibit = true;
      let data = {
        type: this.ischange ? 2 : 1, //购买类型
        buy_id: this.moneyitem.id,
        phone: sessionStorage.getItem("phone"), //手机号
        price: this.moneyitem.price, // 金额
        iccid: this.iccid, // 套餐设备id
        coupon_id:this.selectList[0]?this.selectList[0].id:''
      };
      let Paydata = {
        price: this.moneyitem.price, // 金额
        iccid: this.iccid, // 套餐设备id
        phone: sessionStorage.getItem("phone"), //手机号
        type: this.ischange ? 2 : 1, //购买类型
        buy_id: this.moneyitem.id, // 购买id
        platform: 1, // 1是h5
        openid: sessionStorage.getItem("openid"), // 微信openid
        pay_type: this.payradio == 1 ? 1 : 5, //1是微信 2是余额
        has_free_package: 0,
        coupon_id:this.selectList[0]?this.selectList[0].id:''
      };
      // console.log(Paydata);
      // console.log(data);
      this.checkBalance({ data }).then((res) => {
        if (this.moneyitem.is_default == 1 && res.data.code == 0) {
          this.prohibit = false;
          this.$toast("余额不足,请充值");
          console.log(this.meallistbox);
          // 为了适应小流量套餐 套餐充值金额首个可选项不固定
          // if (this.moneyitem.price <= 50) {
          //      console.log(this.meallistbox[0].id);
          //   this.meallistbox.shift()
          //   this.meallistbox.unshift({
          //     money: this.meallistbox[0].money,
          //     id: this.meallistbox[0].id
          //   })
          //   if (this.activeItme.id == 1) {
          //     // 如果用户没有进行充值金额改变，则需要改变初始默认值
          //     // 改变初始选中的钱数
          //     console.log(this.meallistbox);
          //     this.activeItme.money = this.meallistbox[0].money
          //   }
          // } else {
          //   this.meallistbox.shift()
          //   this.meallistbox.unshift({
          //     money: 80,
          //     id: 1
          //   })
          //   if (this.activeItme.id == 1) {
          //     // 如果用户没有进行充值金额改变，则需要改变初始默认值
          //     // 改变初始选中的钱数
          //     this.activeItme.money = 80
          //   }
          // }
          console.log(this.meallistbox[0]);
          this.active = this.meallistbox[0].id;
          this.activeItme.id = this.meallistbox[0].id;
          this.activeItme.money = this.meallistbox[0].money;
          this.reshow = true;
          return false;
        } else if (res.data.code == 0 && this.moneyitem.is_default == 0) {
          this.$toast("余额不足,你还需要支付" + res.data.price + "元");
          this.setpayinfo(Paydata);
        } else if (res.data.code == 1) {
          this.$toast("余额充足,你将使用余额支付");
          this.setpayinfo(Paydata);
        }
      });
    },
    deepCopy(obj) {
      // 只拷贝对象
      if (typeof obj !== "object") return;
      // 根据obj的类型判断是新建一个数组还是一个对象
      var newObj = obj instanceof Array ? [] : {};
      for (var key in obj) {
        // 遍历obj,并且判断是obj的属性才拷贝
        if (obj.hasOwnProperty(key)) {
          // 判断属性值的类型，如果是对象递归调用深拷贝
          newObj[key] =
            typeof obj[key] === "object" ? this.deepCopy(obj[key]) : obj[key];
        }
      }
      return newObj;
    },
    // 获取套餐列表
    getList() {
      let data = {
        iccid: sessionStorage.getItem("iccid"),
        openid: sessionStorage.getItem("openid"),
        type: 1,
      };

      if (!this.weixin) {
        delete data.openid;
      }

      this.getPackageList({ data }).then((res) => {
        if (res.data.code == 1) {
          this.newList = res.data.list;
          // this.isRecommend = this.newList.filter(res=>{
          //     return res.is_recommend == 1 ? true:false
          // })
          // this.isRecommend = this.isRecommend[0] ? this.isRecommend[0]:{}

          this.list = this.newList[0].list;

          setTimeout(() => {
            this.privilege()
          }, 1000)




          // if(sessionStorage.getItem('privilege') == null){
          //   // console.log(sessionStorage.getItem('privilege'));
          //    sessionStorage.setItem('privilege',1)

          // }



          this.info = res.data.info;
          // console.log(this.info, "info");
          if (this.info.iccid_status != "正常") {
            this.infolist[3].texttop = "#ff0000";
          }
          // 如果是变更/续费套餐套餐的话
          if (res.data.info.cur_buy_id > 0) {
            // console.log(res.data.info.cur_buy_id);
            this.ischange = true;
            this.is_first = 0;
            this.infolist[0].money = res.data.info.c_balance;
            this.infolist[2].money = res.data.info.exp_days;
            this.infolist[3].money = res.data.info.iccid_status;
          }
          this.vantradio = this.list[0].id;
          this.moneyitem = this.list[0];
          // 进行初始数值设置
          this.mealList[0].info = this.moneyitem.show_name;
          this.mealList[1].info = this.moneyitem.con_unit;
          this.mealList[2].info = this.moneyitem.validity_desc
          this.showMealInfo = this.moneyitem.pack_desc
          // "套餐有效期" + this.moneyitem.validity_day + "天";
        } else {
          console.log(res.data.msg);
          // this.$toast(`${res.data.msg}`)
        }
      });
    },
  },

};
</script>
<style lang="less" scoped>
.selectpack {
  padding-bottom: 60px;
  background-color: #f8fafc;

  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.72);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    // transition: all 1s ease-in-out;
    img {
      width: 292px;
      height: 385px;
      // transform: rotateY(180deg);
    }

    .box {
      position: relative;

      .countTo {
        position: absolute;
        color: white;
        font-size: 50px;
        bottom: 100px;
        left: 95px;
        font-family: Impact;
      }

      .subtraction {
        color: #e44029;
        position: absolute;
        left: 56%;
        top: 60%;

      }

      .amount {
        position: absolute;
        bottom: 85px;
        left: 95px;
        width: 80px;
        height: 70px;
        color: rgb(255, 255, 255);
        font-size: 50px;
        font-family: Impact;
        // display: flex;
        // justify-content: center;
      }
    }



    .list-enter-active,
    .list-leave-active {
      transition: transform 1s ease, opacity 1s ease;
    }

    .list-enter-from {
      opacity: 0;
      transform: translateY(50%);
    }

    .list-leave-to {
      opacity: 0;
      transform: translateY(-50%);
    }
  }


  .top {
    width: 100%;
    // background-image: url("~@/assets/images/selectpack/newbg4.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 240px;
  }

  .unicomTop {
    background-image: url("~@/assets/images/selectpack/unicomBg.png") !important;
  }

  .center {
    width: 92%;
    margin: -32px auto 80px;

    .change {
      height: 48px;
      display: flex;
      justify-items: center;
      align-items: center;

      /deep/.smalltitle {
        padding-top: 0;
      }
    }

    .titlebox {
      // background-color: #f8fafb;
      // border-radius: 10px;
      width: 100%;
      height: 54px;
      display: flex;
      align-items: flex-end;

      .data100 {
        color: #384354 !important;
        position: relative;
        height: 54px !important;
        background-color: #f8fafc !important;
        border-top-right-radius: 10px;
        line-height: 64px !important;

        span {
          font-size: 12px;
        }

        .centerNumber {
          font-size: 18px;
          font-weight: 700;
        }

        .recomendIcon {
          position: absolute;
          top: 5px;
          left: -2px;
          width: 36px;
          height: 18px;
        }

        .jiao {
          width: 7px;
          height: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .data300 {
        height: 54px !important;
        background-color: #f8fafc !important;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        line-height: 64px !important;
        color: #384354 !important;

        span {
          font-size: 12px;
        }

        .centerNumber {
          font-size: 18px;
          font-weight: 700;
        }

        .jiao {
          width: 7px;
          height: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .data10 {
        color: #384354 !important;
        height: 54px !important;
        background-color: #f8fafc !important;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        line-height: 64px !important;

        span {
          font-size: 12px;
        }

        .centerNumber {
          font-size: 18px;
          font-weight: 700;
        }

        .jiao {
          width: 7px;
          height: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .tabson {
        position: relative;
        text-align: center;
        height: 44px;
        width: 33.3%;
        line-height: 55px;
        background-color: #ffde8e;
        color: #93a0b3;

        .line {
          position: absolute;
          right: 0;
          top: 15px;
          width: 1px;
          height: 20px;
          background-color: rgb(255, 255, 255);
        }

        .recomendIcon {
          position: absolute;
          top: 5px;
          left: -2px;
          width: 36px;
          height: 18px;
        }
      }

      .tabson1 {
        position: relative;
        border-top-left-radius: 10px;
        background-color: #ffde8e;

        .recomendIcon {
          position: absolute;
          top: 5px;
          left: -2px;
          width: 36px;
          height: 18px;
        }
      }

      .tabson3 {
        border-top-right-radius: 10px;
      }

      .infobox {
        display: flex;
        height: 42px;
        margin-top: 20px;
        padding-bottom: 20px;

        /deep/.isloading {
          color: #8a9ab5;
        }

        .text-top {
          font-size: 12px;
          color: #47525d;

          .isstatus {
            font-size: 14px !important;
            line-height: 20px;
          }

          .sapn {
            font-size: 20px;
            color: #47525d;
            font-weight: 600;
            transform: scale(0.75);
          }
        }
      }
    }

    .mealbox {
      width: 100%;
      // margin-top: 18px;

      .ispurchange {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        // margin-bottom: 28px;

        .left {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
        }

        .right {
          font-size: 12px;
          color: #999999;
        }
      }

      .item-box {
        width: 100%;
        min-height: 46px;
        margin-bottom: 44px;

        .img {
          width: 100%;
          display: block;
        }
      }

      .box {
        width: 100%;
        margin-bottom: 16px;
        min-height: 92px;
        margin-top: 10px;

        /deep/.van-radio-group {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-end;

          // .van-cell-group {
          //   margin-bottom: 0 !important; 
          // }
          .van-radio {
            margin-bottom: 12px;
          }

          .van-radio__icon {
            display: none;
          }

          .van-radio__label {
            width: 168px;
            margin-left: 0;
            line-height: none;
            position: relative;

            .hot {
              width: 33px;
              height: 18px;
              display: block;
              position: relative;
              left: 13px;
              top: 9px;
            }

            .selectradio {
              background-image: url("~@/assets/images/selectpack/newselect.png") !important;
            }

            .haspackage {
              width: 100%;
              height: 62px !important;
              background-image: url("~@/assets/images/selectpack/newdefalut.png") !important;
            }

            .nohaspackage {
              width: 100%;
              height: 62px !important;
              background-image: url("~@/assets/images/selectpack/newselect.png") !important;
            }

            .vanttitlebox {
              width: 100%;
              height: 62px;
              background-image: url("~@/assets/images/selectpack/newdefalut.png");
              background-repeat: no-repeat;
              background-size: cover;
              box-sizing: border-box;
              padding: 0 6px;
              display: flex;
              justify-content: space-between;
              transition: height 0.5s;

              .vanttitle {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 14px;

                .title-left {
                  font-weight: 600;

                  .selectpone {
                    color: #fff !important;
                  }

                  .pone {
                    font-size: 16px;
                    color: #50626e;
                  }

                  .selectptwo {
                    color: #ffeda6 !important;
                  }

                  .ptwo {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    // overflow: hidden;
                    font-size: 12px;
                    // width: ;
                    color: #767793;
                    margin-top: 4px;
                  }
                }

                .title-right {
                  .rightspone {
                    color: #fff !important;
                  }

                  .right-pone {
                    font-size: 22px;
                    color: #50626e;
                    font-family: "bahn";
                    text-align: right;

                    .rightspan {
                      color: #fff !important;
                    }

                    .span {
                      color: #50626e;
                      font-size: 12px;
                      font-weight: 600;
                    }
                  }

                  .srightptwo {
                    color: #a6d6fb !important;
                  }

                  .right-ptwo {
                    font-size: 12px;
                    color: #ff2700;
                    text-align: right;
                  }

                  .firstMonth {
                    position: relative;

                    img {
                      width: 8px;
                      position: absolute;
                      top: -3px;
                      left: 12px;
                    }

                    p {
                      display: block;
                      background-color: #000;
                      position: absolute;
                      top: 1.5px;
                      color: #fff;
                      padding: 0 2px;
                      border-radius: 4px;
                      left: 3px;
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .isweixininfo {
      padding-bottom: 100px;
    }

    .mealinfo {
      width: 100%;
      background-color: #f3f5f7;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 10px;

      .showMealInfo {
        line-height: 20px;
        color: #96a3b4;
      }

      .mealinfo-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        margin-bottom: 24px;

        .met-left {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
        }

        .met-right {
          display: flex;

          .box-right {
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: #cdd1d7;
            margin: 0 4px;
          }

          .met-sright {
            background-color: #4888f3;
          }
        }
      }

      .info {
        display: flex;
        margin-bottom: 16px;

        .info-left {
          display: flex;
          justify-items: center;
          align-items: center;
          margin-right: 30px;

          .img {
            width: 16px;
            display: block;
            margin-right: 6px;
          }

          .text {
            color: #999999;
            font-size: 14px;
          }
        }

        .info-right {
          color: #444444;
          font-size: 14px;
          width: 65%;
          line-height: 24px;
          font-weight: 600;
        }

        .infoadj {
          margin-left: 15px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 60px;
    position: fixed;
    right: 0;
    bottom: 65px;
    background-color: #fff;
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 3;

    .bottom-box {
      display: flex;
      width: 92%;
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;

      .bottom-left {
        display: flex;

        .left {
          display: flex;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;

          .img {
            height: 16px;
            width: 16px;
            display: block;
            margin-bottom: 4px;
          }

          .blb-text {
            font-size: 12px;
            color: #aaaaaa;
            transform: scale(0.8);
            min-width: 24px;
          }
        }

        .img-top {
          height: 30px;
          display: block;
          margin: 0px 24px 0 20px;
        }

        .right {
          display: flex;
          flex-direction: column;

          .money {
            color: #ff0000;
            font-size: 18px;
            display: flex;
            justify-items: center;
            align-items: center;

            .span {
              color: #ff0000;
              font-size: 12px;
            }
          }

          .text {
            font-size: 12px;
            color: #aaaaaa;
            transform: scale(0.8);
            margin-top: 2px;
          }
        }
      }

      .bottom-right {
        .submit {
          background-color: #4888f3;
          border-radius: 10px 16px 4px 4px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          min-width: 120px;
          height: 48px;
          margin: 0 10px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;

          /deep/.van-loading {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .popup-top {
    width: 100%;
    background-color: #f6f8fc;
    height: 86px;

    .pop-center {
      width: 92%;
      margin: 0 auto;
      height: 86px;

      .popup-title {
        padding-top: 15px;
        color: #333333;
        font-size: 14px;
        font-weight: 600;

        .span {
          font-family: "bahn";
        }
      }

      .popup-toast {
        font-size: 12px;
        color: #b6c2d6;
        margin-top: 14px;

        .money {
          font-size: 26px;
          color: #f11a52;
          font-family: "bahn";
        }

        .company {
          color: #f11a52;
          margin-right: 12px;
        }
      }
    }

    .center-box {
      width: 92%;
      margin: 0 auto;

      .center-text {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        margin: 20px 0;
      }

      .moneybox {
        display: flex;
        flex-wrap: wrap;
        // justify-content: ;
      }

      .inmeal {
        color: #dce5f5;
        margin-bottom: 16px;
      }
    }
  }

  .qrcode-img {
    width: 100%;
    display: block;
  }

  .setvan {
    /deep/.van-overlay {
      z-index: 6;

      .re-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        border-radius: 10px;

        .text {
          font-size: 14px;
          height: 90;
          border-bottom: 1px solid #7f7f7f;
          box-sizing: border-box;
          text-align: left;
          padding: 10px 20px;
          line-height: 1.5;
          height: 150px;

          .h3 {
            text-align: center;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .button {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 600;

          .button-left {
            width: 50%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-left: 1px solid #7f7f7f;
          }

          .button-right {
            width: 50%;
            height: 40px;
            text-align: center;
            line-height: 40px;
          }
        }

        .experience {
          box-sizing: border-box;
          padding: 10px;
          width: 95%;
          margin: 20px auto 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }

  .querycenter {
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    font-weight: 600;
  }

  .newquerycenter {
    text-align: center;
    height: 80px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    line-height: 1.3;
    align-items: center;
    justify-items: center;
  }
}
</style>